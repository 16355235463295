import React from "react";
import { graphql, Link } from "gatsby";
import Container from "react-bootstrap/Container";
import HeroCard from "../../components/HeroCard";
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
import Img from "gatsby-image";
import Section from "../Section";

const About = (props) => {
  const { indexData } = props.data;
  const teamMembers = props.data.teamData.edges.map((e) => e.node);
  return (
    <Layout bodyClass="page-about">
      <SEO
        title={indexData.frontmatter.title}
        description={indexData.frontmatter.SEODescription}
        imageUrl={
          props.data.seoImage.frontmatter.headerImage.childImageSharp.fixed.src
        }
        path={indexData.frontmatter.path}
      />
      <HeroCard
        image={indexData.frontmatter.headerImage}
        title={indexData.frontmatter.headerTitle}
        subtitle={indexData.frontmatter.headerSubtitle}
        size="large"
      />
      <Container className="content-container">
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: indexData.html }}
        />
      </Container>
    
      <Section heading="Senior Leadership" className="team-list-container" dark>
        <Container className="team-list">
          {teamMembers.map((teamMember, index) => (
            <figure id={`team-member-${teamMember.name}`} key={index}>
              <Link to={teamMember.url || `#team-member-${teamMember.name}`} target="__blank">
                <Img
                  fluid={teamMember.image.childImageSharp.fluid}
                  alt={`Photo of ${teamMember.name}`}
                  style={{ height: "150px", width: "150px" }}
                  imgStyle={{ objectFit: "cover" }}
                />
              </Link>
              <figcaption className="name">{teamMember.name}</figcaption>
              <figcaption className="designation">
                {teamMember.designation}
              </figcaption>
              <figcaption className="description">
                {teamMember.description}
              </figcaption>
            </figure>
          ))}
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query AboutQuery {
    indexData: markdownRemark(fileAbsolutePath: { regex: "/about.md/" }) {
      frontmatter {
        title
        SEODescription
        path
        headerImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        headerTitle
        headerSubtitle
      }
      html
    }
    seoImage: markdownRemark(fileAbsolutePath: { regex: "/about.md/" }) {
      frontmatter {
        headerImage {
          childImageSharp {
            fixed(width: 600) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
          extension
          publicURL
        }
      }
    }
    teamData: allTeamJson {
      edges {
        node {
          description
          designation
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          name
          url
        }
      }
    }
  }
`;

export default About;
